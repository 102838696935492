<template>
  <div id="virtual-infinite-scroll" ref="virtual-infinite-scroll" class="virtual-infinite-scroll">
    <div id="virtual-infinite-scroll-viewport" ref="virtual-infinite-scroll-viewport" :style="viewportStyle">
      <div
          id="virtual-infinite-scroll-spacer"
          ref="virtual-infinite-scroll-spacer"
          :class="containerClass"
          :style="{...containerStyle, ...spacerStyle}">
        <template v-for="(visibleItem, index) in visibleItems">
          <div
              :key="visibleItem.key"
              :data-index="visibleItem.index"
              class="item" :class="itemClass"
              :style="itemStyle"
              @click.self="itemClick(visibleItem)">
            <slot name="item" v-bind="{data: visibleItem}"/>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VirtualInfiniteScroll",

  props: {
    containerClass: {
      type: String,
    },

    containerStyle: {
      type: Object,
      default: () => ({}),
    },

    itemClass: {
      type: String,
    },

    itemStyle: {
      type: Object,
      default: () => ({}),
    },

    items: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
      default: false,
    },

    pageSize: {
      type: Number,
      default: 25,
    }
  },

  model: {
    prop: 'loading',
    event: 'update:loading',
  },

  data() {
    return {
      isMounted: false,
      pageStartIndex: 0,
      startIndex: 0,
      endIndex: this.pageSize,
      heights: [],
      rollingPageHeights: [],
      smallestRowHeight: Number.MAX_SAFE_INTEGER,
      largestRowHeight: Number.MIN_SAFE_INTEGER,
      translateY: 0,
      scrollTop: 0,
      renderAhead: 10,
      pagination: {
        page: 0,
        perPage: this.pageSize,
        lastPage: 1,
      },
    };
  },

  computed: {
    viewportHeight() {
      return this.isMounted ? this.rollingPageHeights[this.rollingPageHeights.length - 1] || 0 : 0;
    },

    rootHeight() {
      return this.isMounted ? this.$el.offsetHeight : 0;
    },

    rowPositions() {
      const currentHeights = this.heights.slice(
          this.pageStartIndex * this.pageSize,
          (this.pageStartIndex + 1) * this.pageSize
      );
      let displacements = [];
      let totalDisplacement = this.rollingPageHeights[this.pageStartIndex - 1] || 0;
      for (let i = 0; i < currentHeights.length; i++) {
        displacements.push(totalDisplacement);
        totalDisplacement += currentHeights[i];
      }
      displacements.push(totalDisplacement);
      return displacements;
    },

    visibleItems() {
      const distance = this.endIndex - this.startIndex;
      const endIndex = (this.endIndex + Math.abs(distance - this.pageSize)) <= this.items.length
          ? (this.endIndex + Math.abs(distance - this.pageSize))
          : this.items.length;
      return this.items.slice(this.startIndex, endIndex);
    },

    spacerStyle() {
      return {
        willChange: "auto",
        transform: "translateY(" + this.translateY + "px)",
      };
    },

    viewportStyle() {
      return {
        height: this.viewportHeight + "px",
        overflow: "hidden",
        position: "relative",
        willChange: "auto"
      };
    },
  },

  watch: {
    items(newValue) {
      this.$nextTick(() => {
        if (newValue.length) {
          this.update();
        } else {
          this.reset();
        }
      });
    },

    scrollTop(newValue) {
      this.pageStartIndex = this.binarySearch(this.rollingPageHeights, newValue);
      const startNodeIndex = this.findStartNode(
          newValue,
          this.rowPositions,
          this.rowPositions.length
      );
      this.startIndex = this.pageStartIndex * this.pageSize + startNodeIndex;
      this.endIndex = this.startIndex + Math.floor(this.rootHeight / this.smallestRowHeight);
      this.translateY = this.rowPositions[startNodeIndex];
    }
  },

  methods: {
    init() {
      this.$el.addEventListener(
          "scroll",
          this.handleScroll,
          this.doesBrowserSupportPassiveScroll() ? {
            passive: true
          } : false
      );

      this.$nextTick(() => {
        this.loadData();
        this.isMounted = true;
      });
    },

    binarySearch(arr, x) {
      let low = 0;
      let high = Array.isArray(arr) ? arr.length - 1 : Object.keys(arr).length - 1;
      let mid;
      while (low < high) {
        mid = Math.floor((high + low) / 2);
        if (arr[mid] === x) {
          break;
        } else if (arr[mid] > x) {
          high = mid - 1;
        } else {
          low = mid + 1;
        }
      }
      mid = Math.floor((high + low) / 2);
      if (x <= arr[mid]) return mid;
      else return mid + 1;
    },

    findStartNode(scrollTop, nodePositions, itemCount) {
      let startRange = 0;
      let endRange = itemCount - 1;
      while (endRange !== startRange) {
        const middle = Math.floor((endRange - startRange) / 2 + startRange);
        if (
            nodePositions[middle] <= scrollTop &&
            nodePositions[middle + 1] > scrollTop
        ) {
          return middle;
        }
        if (middle === startRange) {
          return endRange;
        } else {
          if (nodePositions[middle] <= scrollTop) {
            startRange = middle;
          } else {
            endRange = middle;
          }
        }
      }
      return 0;
    },

    doesBrowserSupportPassiveScroll() {
      let passiveSupported = false;

      try {
        const options = {
          get passive() {
            passiveSupported = true;
            return false;
          }
        };

        window.addEventListener("test", null, options);
        window.removeEventListener("test", null, options);
      } catch (err) {
        passiveSupported = false;
      }
      return passiveSupported;
    },

    scrollTo(index) {
      const pageStartIndex = Math.floor(index / this.pageSize);

      const currentHeights = this.heights.slice(
          pageStartIndex * this.pageSize,
          (pageStartIndex + 1) * this.pageSize
      );
      let totalDisplacement = this.rollingPageHeights[pageStartIndex - 1] || 0;
      let displacements = [];
      for (let i = 0; i < currentHeights.length; i++) {
        displacements.push(totalDisplacement);
        totalDisplacement += currentHeights[i];
      }
      displacements.push(totalDisplacement);
      const top = displacements[index % this.pageSize];
      const isVisible = top >= this.scrollTop && top <= this.scrollTop + this.$el.offsetHeight;
      if (!isVisible) {
        this.$el.scrollTo({
          left: 0,
          top: displacements[index % this.pageSize],
          behavior: "smooth"
        });
      }
    },

    updatePageHeights(pageIndices) {
      for (let i = 0; i < pageIndices.length; i++) {
        const pageStartIndex = pageIndices[i];
        const startIndex = pageStartIndex * this.pageSize;
        const endIndex = (pageStartIndex + 1) * this.pageSize;
        const heightsSlice = this.heights.slice(startIndex, endIndex);
        this.$set(
            this.rollingPageHeights,
            pageStartIndex,
            (this.rollingPageHeights[pageStartIndex - 1] || 0) +
            heightsSlice.reduce((a, b) => a + b)
        );
      }
    },

    update() {
      const children = this.$refs['virtual-infinite-scroll-spacer'].children;
      let pageIndices = new Set();
      for (let i = 0; i < children.length; i++) {
        const {scrollHeight} = children[i];
        const index = children[i].getAttribute("data-index");
        this.$set(this.heights, index, scrollHeight);
        this.largestRowHeight = scrollHeight > this.largestRowHeight ? scrollHeight : this.largestRowHeight;
        this.smallestRowHeight = scrollHeight < this.smallestRowHeight ? scrollHeight : this.smallestRowHeight;
        const pageIndex = Math.floor(index / this.pageSize);
        pageIndices.add(pageIndex);
      }
      pageIndices = Array.from(pageIndices);
      this.updatePageHeights(pageIndices);
    },

    handleScroll() {
      const {scrollTop, offsetHeight, scrollHeight} = this.$el;
      this.scrollTop = scrollTop;
      if (scrollTop + offsetHeight >= scrollHeight - 10) {
        this.loadData();
      }
    },

    loadData() {
      this.$nextTick(() => {
        if (this.loading || this.pagination.page === this.pagination.lastPage) return;

        this.$emit('update:loading', true);
        this.$emit('loadData', {
          page: this.pagination.page + 1,
          perPage: this.pagination.perPage,
          lastPage: this.pagination.lastPage,
        }, this.onDataLoadCompleted);
      });
    },

    onDataLoadCompleted(pagination) {
      this.$nextTick(() => {
        this.pagination = {
          page: pagination.page || 0,
          perPage: pagination.perPage || this.pageSize,
          lastPage: pagination.lastPage || 1,
        };
        this.$emit('update:loading', false);
      });
    },

    reset() {
      this.$set(this, 'scrollTop', 0);
      this.$set(this, 'pageStartIndex', 0);
      this.$set(this, 'startIndex', 0);
      this.$set(this, 'endIndex', 0);
      this.$set(this, 'heights', []);
      this.$set(this, 'rollingPageHeights', []);
      this.$set(this, 'translateY', 0);
      this.$set(this, 'renderAhead', 10);
      this.$set(this, 'smallestRowHeight', Number.MAX_SAFE_INTEGER);
      this.$set(this, 'largestRowHeight', Number.MIN_SAFE_INTEGER);
      this.$set(this, 'pagination', {
        page: 0,
        perPage: this.pageSize,
        lastPage: 1,
      });
    },

    itemClick(item) {
      this.$emit('itemClick', item);
    },

    deleted(indexes) {
      indexes.forEach(index => {
        this.$set(
            this.rollingPageHeights,
            this.rollingPageHeights.length - 1,
            (this.rollingPageHeights[this.rollingPageHeights.length - 1] ?? 0) -
            (this.heights[index] ?? 0)
        );
        this.heights.splice(index, 1);
      });

      this.$nextTick(() => {
        const {scrollTop, offsetHeight, scrollHeight} = this.$el;
        if ((scrollHeight < 2 * offsetHeight) || (scrollTop + offsetHeight >= scrollHeight - 10)) {
          this.loadData();
        }
      });
    },
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    this.$el.removeEventListener("scroll", this.handleScroll);
    this.isMounted = false;
  },
}
</script>

<style scoped>
.virtual-infinite-scroll {
  height: 100%;
  overflow: auto;
}
</style>
