<template>
  <b-tab active class="email-domain-blacklists" title="Email Domain Blacklists">
    <template #title>
      <feather-icon icon="MailIcon" size="18"/>
      <span class="align-middle">Email Domain Blacklists</span>
    </template>
    <b-row>
      <b-col cols="auto">
        <b-overlay :show="overlayShow" bg-color="#161d31 !important;">
          <template #overlay>
            <div class="d-flex justify-content-center" style="gap: 0.5rem;">
              <b-spinner variant="primary" style="min-height: 2rem; min-width: 2rem;"/>
              <h3 class="mb-0">{{ overlayText }}</h3>
            </div>
          </template>
          <b-card no-body>
            <b-card-header class="flex-nowrap justify-content-between p-1" style="gap: 3rem;">
              <b-card-title class="font-medium-2">
                <feather-icon icon="MailIcon" size="18"/>
                <span class="align-middle ml-50">Email Domain Blacklists</span>
              </b-card-title>
              <b-dropdown
                  no-caret
                  right
                  variant="icon btn-flat-dark p-50"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  style="margin: -0.5rem;">
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="18"/>
                </template>
                <b-dropdown-item-button button-class="w-100" @click="addDomain">Add Domain</b-dropdown-item-button>
              </b-dropdown>
            </b-card-header>
            <b-card-body ref="cardBody" class="p-0">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon"/>
                </b-input-group-prepend>
                <b-form-input v-model="search" placeholder="Search"/>
              </b-input-group>
              <virtual-infinite-scroll
                  ref="listComponent"
                  v-model:loading="loading"
                  :items="list"
                  :page-size="25"
                  container-class="list-group list-group-flush"
                  :container-style="{width: '100%'}"
                  item-class="list-group-item d-flex flex-nowrap justify-content-between pl-1 pr-75 py-75"
                  :item-style="{gap: '1rem', width: '100%'}"
                  style="height: 259px; max-width: 24rem;"
                  @itemClick="toggleChecked"
                  @loadData="handleLoadData">
                <template #item="{data}">
                  <div
                      class="d-flex align-items-center"
                      style="flex-grow: 1; width: calc(100% - 60px - 1rem);"
                      @click="toggleChecked(data)">
                    <b-form-checkbox v-show="data.checked" v-model="data.checked"/>
                    <span
                        v-show="!data.editMode"
                        style="flex-grow: 1; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                      {{ data.domain }}
                    </span>
                    <b-form-input
                        v-show="!!data.editMode"
                        v-model="data.inputDomain"
                        placeholder="Domain"
                        size="sm"/>
                  </div>
                  <div class="d-flex flex-nowrap">
                    <b-button
                        v-show="data.editMode"
                        class="btn-icon p-50"
                        variant="flat-success"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        :disabled="data.domain === data.inputDomain"
                        @click="save(data)">
                      <feather-icon icon="CheckIcon"/>
                    </b-button>
                    <b-button
                        v-show="data.editMode"
                        class="btn-icon p-50"
                        variant="flat-danger"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        @click="cancel(data)">
                      <feather-icon icon="XIcon"/>
                    </b-button>
                    <b-button
                        v-show="!data.editMode"
                        class="btn-icon p-50"
                        variant="flat-success"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        @click="toggleEditMode(data)">
                      <feather-icon icon="EditIcon"/>
                    </b-button>
                    <b-button
                        v-show="!data.editMode"
                        class="btn-icon p-50"
                        variant="flat-danger"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        @click="destroy(data)">
                      <feather-icon icon="DeleteIcon"/>
                    </b-button>
                  </div>
                </template>
              </virtual-infinite-scroll>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import {destroy, index, store, update} from '@/api/disposable-email-domains.api';
import VirtualInfiniteScroll from '@/components/VirtualInfiniteScroll.vue';

export default {
  name: "EmailDomainBlacklistsTab",
  components: {
    VirtualInfiniteScroll,
  },
  data() {
    return {
      domainList: [],
      deleting: false,
      loading: false,
      storing: false,
      updating: false,
      search: '',
      lastSearch: '',
      searchTimer: undefined,
      overlayText: 'Loading...',
    };
  },
  computed: {
    list() {
      return this.domainList.map((value, index) => {
        value.index = index;
        return value;
      });
    },
    overlayShow() {
      return this.deleting || this.loading || this.storing || this.updating;
    },
    listComponent() {
      return this.$refs.listComponent;
    },
  },
  watch: {
    deleting() {
      if (this.deleting) {
        this.overlayText = 'Deleting...';
      }
    },
    loading() {
      if (this.loading) {
        this.overlayText = 'Loading...';
      }
    },
    storing() {
      if (this.storing) {
        this.overlayText = 'Storing...';
      }
    },
    updating() {
      if (this.updating) {
        this.overlayText = 'Updating...';
      }
    },
    search(newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = undefined;
      }

      if (newValue !== this.lastSearch) {
        this.searchTimer = setTimeout(() => {
          this.lastSearch = newValue;
          this.domainList = [];
          this.$nextTick(() => this.listComponent.loadData());
        }, 1000);
      }
    },
  },
  methods: {
    cancel(data) {
      if (data.id) {
        data.inputDomain = data.domain;
        this.toggleEditMode(data);
      } else {
        this.destroy(data);
      }
    },
    destroy(data) {
      if (data.id) {
        this.deleting = true;
        destroy(data.id).then(() => {
          this.listComponent.deleted([data.index]);
          this.domainList.splice(data.index, 1);
        }).finally(() => {
          this.deleting = false;
        });
      } else {
        this.listComponent.deleted([data.index]);
        this.domainList.splice(data.index, 1);
      }
    },
    save(data) {
      if (data.id) {
        this.updating = true;
        update(data.id, {
          id: data.id,
          domain: data.inputDomain,
        }).then((response) => {
          const find = this.domainList.find((data_) => data_.domain === data.inputDomain);
          if (find) {
            data.id = undefined;
            this.destroy(data);
          } else {
            Object.assign(data, {
              ...data,
              id: response.data.id,
              domain: response.data.domain,
              inputDomain: response.data.domain,
            });
            this.toggleEditMode(data);
          }
        }).finally(() => {
          this.updating = false;
        });
      } else {
        this.storing = true;
        store({
          domain: data.inputDomain
        }).then(() => {
          this.destroy(data);
        }).finally(() => {
          this.storing = false;
        });
      }
    },
    toggleChecked(data) {
      if (!data.editMode) {
        data.checked = !data.checked
      }
    },
    toggleEditMode(data) {
      if (!data.editMode && data.checked) {
        this.toggleChecked(data);
      }
      data.editMode = !data.editMode;
    },
    handleLoadData(pagination, loadCompleted) {
      index({
        page: pagination.page,
        perPage: pagination.perPage,
        search: this.search || undefined
      }).then(({data}) => {
        this.domainList.push(...(data.data || []).map((value) => {
          const {id, domain} = value;
          return {
            id,
            domain,
            inputDomain: domain,
            checked: false,
            editMode: false,
          };
        }));
        loadCompleted({
          page: data.meta.current_page,
          perPage: data.meta.per_page,
          lastPage: data.meta.last_page,
        });
      });
    },
    addDomain() {
      this.domainList.unshift({
        domain: '',
        inputDomain: '',
        checked: false,
        editMode: true,
      });

      this.$nextTick(() => {
        if (this.listComponent.scrollTop) {
          this.listComponent.scrollTo(0);
        }
      });
    }
  },
  beforeMount() {
    //
  },
  mounted() {
    //
  }
}
</script>

<style scoped>
.email-domain-blacklists::v-deep .bg-light {
  background-color: #161d31 !important;
}

.email-domain-blacklists::v-deep .list-group .list-group-item:not([class*=list-group-item-]) {
  cursor: pointer;
}

.dark-layout .email-domain-blacklists::v-deep .list-group .list-group-item:not([class*=list-group-item-]):active {
  background-color: #161d31;
  color: unset;
}
</style>
