<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="auto">
        <go-back style="margin-bottom: 0 !important;">
          <h1 class="mb-0">
            <feather-icon icon="SettingsIcon" size="32"/>
            <span class="align-middle ml-50">{{ $t('menu.system-settings') }}</span>
          </h1>
        </go-back>
      </b-col>
    </b-row>
    <b-tabs
        pills vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        nav-class="nav-left"
        nav-wrapper-class="col-12 col-md-3">
      <email-domain-blacklists-tab/>
    </b-tabs>
  </div>
</template>

<script>
import GoBack from "@/components/Common/GoBack";
import EmailDomainBlacklistsTab from "@/views/admin/SystemSettings/EmailDomainBlacklistsTab";

export default {
  name: "SystemSettings",
  components: {
    GoBack,
    EmailDomainBlacklistsTab,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
}
</script>

<style scoped>
</style>
